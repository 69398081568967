import React, { useState, useEffect } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import FeedStatisticsIcons from './icons';
import FeedStatisticsLatestUpdate from './latest-update';
import FeedStatisticsLatestPublish from './latest-publish';

import '../styles/main.scss';

/**
 * Show (numeric) information about the latet feed update and auto publish actions.
 * @param {*} param0
 * @returns
 */
const FeedStatistics = ({ dataSet }) => {
    const [autoPublishOriginal, setAutoPublishOriginal] = useState(null);
    const [latestUpdateDate, setLatestUpdateDate] = useState(
        dataSet && dataSet.metadata && dataSet.metadata.latestUpdate && dataSet.metadata.latestUpdate.date ? dataSet.metadata.latestUpdate.date : null
    );
    const [manual, setManual] = useState(dataSet && dataSet.customData && dataSet.customData.isManual);
    const [updateFailed, setUpdateFailed] = useState(dataSet && dataSet.latestUpdate && dataSet.latestUpdate.errorMessage);
    const [autoPublishFailed, setAutoPublishFailed] = useState(dataSet && dataSet.latestPublish && dataSet.latestPublish.errorMessage);

    /**
     * Make sure the getAutoPublishDetails method is only run when change can be expected.
     * So, when autoPublish settings change.
     * Or, in case of runAfterPoll, when the last import date changes.
     */
    useEffect(() => {
        if (dataSet) {
            const autoPublish = dataSet.autoPublish ? dataSet.autoPublish : null;
            const latestUpdate = dataSet.latestUpdate ? dataSet.latestUpdate : {};
            if (!isEqual(autoPublish, autoPublishOriginal) || (autoPublish && autoPublish.runAfterPoll && !isEqual(latestUpdate.date, latestUpdateDate))) {
                setLatestUpdateDate(latestUpdate && latestUpdate.date ? latestUpdate.date : null);
                setAutoPublishOriginal(autoPublish);
            }
            if (dataSet.customData && !isEqual(dataSet.customData.isManual, manual)) {
                setManual(dataSet.customData.isManual);
            }
            setUpdateFailed(dataSet && dataSet.latestUpdate && dataSet.latestUpdate.errorMessage);
            setAutoPublishFailed(dataSet && dataSet.latestPublish && dataSet.latestPublish.errorMessage);
        }
    }, [dataSet]);

    const isUpdating = dataSet && dataSet.latestUpdate && dataSet.latestUpdate.isUpdating;
    const isPublishing = dataSet && dataSet.latestPublish && dataSet.latestPublish.isPublishing;

    return (
        <div className="feed-management-feed-statistics">
            <div className="feed-management-feed-statistics__latest">
                <div className="feed-management-feed-statistics__latest__header">
                    {Translation.get('feedStatistics.latestUpdate', 'feed-management', {
                        updateType: manual ? Translation.get('manual', 'feed-management') : Translation.get('feed', 'feed-management'),
                        updateStatus: updateFailed ? Translation.get('feedback.errors.failed', 'common') : ''
                    })}
                </div>
                <div
                    className={classNames('feed-management-feed-statistics__latest__date', {
                        'feed-management-feed-statistics__latest__date--error':
                            dataSet && dataSet.latestUpdate && dataSet.latestUpdate.errorMessage && !isUpdating
                    })}>
                    {isUpdating ? (
                        <span>
                            <CircularProgress size={12} /> {Translation.get('feedback.processing.processing', 'common')}
                        </span>
                    ) : (
                        <React.Fragment>
                            <Icon fontSize="inherit" className="feed-management-feed-statistics__latest__icon">
                                update
                            </Icon>
                            <FeedStatisticsLatestUpdate dataSet={dataSet} manual={manual} />
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="feed-management-feed-statistics__icons">{!manual && <FeedStatisticsIcons latestUpdate={dataSet && dataSet.latestUpdate} />}</div>
            {dataSet.autoPublish && (
                <div className="feed-management-feed-statistics__latest">
                    <div className="feed-management-feed-statistics__latest__header">
                        {Translation.get('feedStatistics.latestAutoPublishUpdate', 'feed-management', {
                            updateStatus: autoPublishFailed ? Translation.get('feedback.errors.failed', 'common') : ''
                        })}
                    </div>
                    <div className="feed-management-feed-statistics__latest__date">
                        {isPublishing ? (
                            <span>
                                <CircularProgress size={12} /> {Translation.get('feedback.processing.publishing', 'common')}
                            </span>
                        ) : (
                            <FeedStatisticsLatestPublish latestPublish={dataSet.latestPublish} campaignId={dataSet.autoPublish.campaignId} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedStatistics;
