import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import '../styles/icons.scss';

const StatisticsIcons = ({ latestUpdate = {} }) => {
    if (latestUpdate.errorMessage && latestUpdate.errorMessage.length > 0) return null;

    return (
        <div className="feed-management-feed-statistics-icons">
            <div className="feed-management-feed-statistics-icons__updated">
                <Tooltip title="Records updated">
                    <Icon fontSize="inherit">refresh</Icon>
                </Tooltip>{' '}
                {latestUpdate.updated !== undefined ? latestUpdate.updated : '-'}
            </div>
            <div className="feed-management-feed-statistics-icons__added">
                <Tooltip title="Records added">
                    <Icon fontSize="inherit">add_circle_outline</Icon>
                </Tooltip>{' '}
                {latestUpdate.created !== undefined ? latestUpdate.created : '-'}
            </div>
            <div className="feed-management-feed-statistics-icons__removed">
                <Tooltip title="Records removed">
                    <Icon fontSize="inherit">clear</Icon>
                </Tooltip>{' '}
                {latestUpdate.deleted !== undefined ? latestUpdate.deleted : '-'}
            </div>
        </div>
    );
};
export default StatisticsIcons;
