import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from 'components/ui-components-v2/Menu';
import MenuItem from 'components/ui-components-v2/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { AutoPublishFormDialog } from '../../AutoPublishForm';
import { FeedFormDialog } from '../../FeedForm';

import '../styles/actions.scss';

/**
 * Display the actions part of a feed
 * @param {*} param0
 * @returns
 */
const FeedActions = ({ dataSet, feed, feedFormSetup, onGetDatasetItems }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFeedForm, setShowFeedForm] = useState(false);
    const [showRemoveFeed, setShowRemoveFeed] = useState(false);
    const [removingFeed, setRemovingFeed] = useState(false);
    const [showAutoPublishForm, setShowAutoPublishForm] = useState(false);
    const [showRemoveItems, setShowRemoveItems] = useState(false);
    const [showForcePublish, setShowForcePublish] = useState(false);
    const [removingItems, setRemovingItems] = useState(false);

    /**
     * Open and close the dropdown menu
     * @param {*} event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /** Remove this feed */
    const removeFeed = () => {
        setRemovingFeed(true);
        FeedRequest.delete(`dataset/${dataSet._id}/feed/${feed.feedId}`)
            .then(() => {
                // Reload the entire feed list after editing
                SnackbarUtils.success(Translation.get('feeds.feedActions.removedFeed', 'feed-management'));
                FeedHelpers.reloadDataSet(dataSet._id);
                setRemovingFeed(false);
                setShowRemoveFeed(false);
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.feedActions.removingFeedFailed', 'feed-management'));
                console.error('Removing feed failed', error);
            });
    };

    /**
     * Remove the entrie belonging to the active dataset
     */
    const removeItems = async () => {
        setRemovingItems(true);
        await FeedRequest.delete(`/dataset/${dataSet._id}/item`)
            .then(() => {
                SnackbarUtils.success(Translation.get('feeds.feedActions.removedItems', 'feed-management'));
                FeedHelpers.reloadDataSet(dataSet._id);
                onGetDatasetItems();
                setRemovingItems(false);
                setShowRemoveItems(false);
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.feedActions.removingItemsFailed', 'feed-management'));
                console.error('Removing items failed', error);
            });
    };

    /** Force run update this feed */
    const forceUpdate = () => {
        const isUpdating = dataSet.latestUpdate && dataSet.latestUpdate.isUpdating;
        if (!isUpdating) {
            FeedRequest.get(`dataset/${dataSet._id}/feed/${feed.feedId}/force`)
                .then(() => {
                    FeedHelpers.patchDataSetUpdate(dataSet._id, 0, {
                        ...dataSet.latestUpdate,
                        isUpdating: true
                    });
                    SnackbarUtils.success(Translation.get('feeds.feedActions.feedUpdating', 'feed-management'));
                })
                .catch((error) => {
                    console.error(error);
                    SnackbarUtils.error(Translation.get('feedUpdatingFailed', 'feed-management'));
                });
        } else {
            SnackbarUtils.warning(Translation.get('feeds.feedActions.feedUpdatingRunning', 'feed-management'));
        }
    };

    /** Force publish this feed */
    const forcePublish = (republish = false) => {
        const isPublishing = dataSet && dataSet.latestPublish && dataSet.latestPublish.isPublishing;
        const autoPublishEnabled = dataSet && dataSet.autoPublish && dataSet.autoPublish.enabled;
        if (!autoPublishEnabled) {
            SnackbarUtils.warning(Translation.get('feeds.feedActions.autoPublishEnabled', 'feed-management'));
        } else if (!isPublishing) {
            FeedRequest.get(`dataset/${dataSet._id}/publish/force`, { params: { republish } })
                .then(() => {
                    FeedHelpers.patchDataSetPublish(dataSet._id, 0, {
                        ...dataSet.latestPublish,
                        isPublishing: true
                    });
                    if (republish) setShowForcePublish(false);
                    SnackbarUtils.success(
                        republish
                            ? Translation.get('feeds.feedActions.feedPublishingForce', 'feed-management')
                            : Translation.get('feeds.feedActions.feedPublishing', 'feed-management')
                    );
                })
                .catch((error) => {
                    console.error(error);
                    SnackbarUtils.error(Translation.get('feeds.feedActions.feedPublishingFailed', 'feed-management'));
                });
        } else {
            SnackbarUtils.warning(Translation.get('feeds.feedActions.feedPublishingRunning', 'feed-management'));
        }
    };

    /**
     * Format the number to display the number of items in a data set.
     * @param {number} num
     * @returns
     */
    const numberFormatter = (num = 0) => {
        return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);
    };

    return (
        <div className="feed-management__feed-actions">
            {dataSet.metadata && dataSet.metadata.itemCount !== undefined && (
                <React.Fragment>
                    <div className="feed-management__feed-actions__unit">{Translation.get('feeds.feedActions.entries', 'feed-management')}</div>
                    <div className="feed-management__feed-actions__number">
                        <span>{numberFormatter(dataSet.metadata.itemCount)}</span>
                    </div>
                    <div className="feed-management__feed-actions__divider">&nbsp;</div>
                </React.Fragment>
            )}
            <Tooltip title={Translation.get('feeds.feedActions.moreOptions', 'feed-management')}>
                <IconButton aria-label="more" onClick={(event) => handleMenuOpen(event)}>
                    <Icon>more_horiz</Icon>
                </IconButton>
            </Tooltip>
            <Menu
                id="feed-actions"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {feed && feed.feedId && !dataSet.autoPublish && (
                    <MenuItem
                        onClick={() => {
                            setShowRemoveFeed(true);
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>delete</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.feedActions.removeFeed', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        setShowRemoveItems(true);
                        setAnchorEl(null);
                    }}>
                    <ListItemIcon>
                        <Icon>delete</Icon>
                    </ListItemIcon>
                    <ListItemText>{Translation.get('feeds.feedActions.removeItems', 'feed-management')}</ListItemText>
                </MenuItem>
                {(!dataSet.customData || !dataSet.customData.isManual) && (
                    <MenuItem
                        onClick={() => {
                            setShowFeedForm(true);
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>create</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.addForm.editFeed', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
                {dataSet.customData && dataSet.customData.isManual && dataSet.autoPublish && (
                    <MenuItem
                        onClick={() => {
                            setShowAutoPublishForm(true);
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>update</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.feedActions.autoPublishSchedule', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
                {(!dataSet.customData || !dataSet.customData.isManual) && (
                    <MenuItem
                        onClick={() => {
                            forceUpdate();
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>refresh</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.feedActions.updateFeed', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
                {dataSet.autoPublish && (
                    <MenuItem
                        onClick={() => {
                            forcePublish(false);
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>rocket</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.feedActions.publishFeed', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
                {dataSet.autoPublish && (
                    <MenuItem
                        onClick={() => {
                            setShowForcePublish(true);
                            setAnchorEl(null);
                        }}>
                        <ListItemIcon>
                            <Icon>rocket</Icon>
                        </ListItemIcon>
                        <ListItemText>{Translation.get('feeds.feedActions.publishFeedForce', 'feed-management')}</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            {showFeedForm && (
                <FeedFormDialog
                    view={'modal'}
                    dataSetId={dataSet._id}
                    feed={feed}
                    setup={feedFormSetup}
                    structure={dataSet.structure ? Object.keys(dataSet.structure) : []}
                    onClose={() => setShowFeedForm(false)}
                    callback={() => FeedHelpers.reloadDataSet(dataSet._id)}
                />
            )}
            {showRemoveFeed && (
                <ConfirmDialog
                    title={Translation.get('feeds.feedActions.removeFeed', 'feed-management')}
                    description={Translation.get('feeds.feedActions.confirmDialogRemoveFeed', 'feed-management', {
                        name: feed.name
                    })}
                    onConfirm={() => removeFeed()}
                    open={true}
                    waiting={removingFeed}
                    onClose={() => setShowRemoveFeed(false)}
                />
            )}
            {showRemoveItems && (
                <ConfirmDialog
                    title={Translation.get('feeds.feedActions.removeItems', 'feed-management')}
                    description={Translation.get('feeds.feedActions.confirmDialogRemoveAllItems', 'feed-management')}
                    onConfirm={() => removeItems()}
                    open={true}
                    waiting={removingItems}
                    onClose={() => setShowRemoveItems(false)}
                />
            )}
            {showForcePublish && (
                <ConfirmDialog
                    title={Translation.get('feeds.feedActions.publishFeedForce', 'feed-management')}
                    description={Translation.get('feeds.feedActions.confirmPublishFeedForce', 'feed-management')}
                    onConfirm={() => forcePublish(true)}
                    open={true}
                    waiting={removingItems}
                    onClose={() => setShowForcePublish(false)}
                />
            )}
            {showAutoPublishForm && <AutoPublishFormDialog dataSet={dataSet} onClose={() => setShowAutoPublishForm(false)} />}
        </div>
    );
};

export default FeedActions;
