import React from 'react';
import AlertBox from 'components/ui-components/AlertBox';

/**
 * Display warnings and errors about feeds.
 * @param {*} param0
 * @returns
 */
const FeedAlerts = ({ dataSet, warning, onClearWarning }) => {
    return (
        <React.Fragment>
            {dataSet && dataSet.latestUpdate && dataSet.latestUpdate && dataSet.latestUpdate.errorMessage && (
                <AlertBox type="error" key="error" hideClose>
                    {dataSet.latestUpdate.errorMessage}
                </AlertBox>
            )}
            {dataSet && dataSet.latestPublish && dataSet.latestPublish && dataSet.latestPublish.errorMessage && (
                <AlertBox type="error" key="error" hideClose>
                    {dataSet.latestPublish.errorMessage}
                </AlertBox>
            )}
            {!!warning && (
                <AlertBox type="warning" key="warning" onClose={onClearWarning}>
                    {warning}
                </AlertBox>
            )}
        </React.Fragment>
    );
};

export default FeedAlerts;
