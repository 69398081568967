import React from 'react';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FormFlow from 'components/ui-base/FormFlow';
import '../styles/data-set-form-settings.scss';
import Translation from 'components/data/Translation';

/**
 * Get an array of key paths with value from a (nested) object.
 * @param {object} object
 * @param {string} temp
 * @returns
 */
const getPathes = (object, temp = '') => {
    return Object.entries(object).reduce(
        (r, [key, value]) => ((path) => r.concat(value && typeof value === 'object' ? getPathes(value, path) : { path, value }))(temp + (temp && '.') + key),
        []
    );
};

/** Add or edit a feed to / of a data set */
const FeedManagementDataSetFormSettings = ({ dataSet = {}, view, modalOpen, title, onClose = () => {}, callback = () => {} }) => {
    const setup = [
        {
            items: [
                {
                    type: 'text',
                    model: 'customData.title',
                    title: Translation.get('settings.name', 'feed-management'),
                    validators: 'required'
                }
            ]
        }
    ];

    // If an id is present we're editing instead of creating.
    if (dataSet && dataSet._id) {
        // When editing the dataset, you can also edit the mapping of the datacard
        setup[0]['items'].push({
            type: 'text',
            model: 'compoundIndex',
            title: Translation.get('settings.compoundIndexTitle', 'feed-management'),
            description: Translation.get('settings.compoundIndexDescription', 'feed-management')
        });

        // When editing the dataset, you can also edit the mapping of the datacard
        setup[0]['items'].push({
            type: 'json',
            model: 'customData.dataSetStructure',
            title: Translation.get('settings.dataSetStructureTitle', 'feed-management'),
            description: Translation.get('settings.dataSetStructureDescription', 'feed-management')
        });

        // When editing the dataset, you can also edit the mapping of the datacard
        setup[0]['items'].push({
            type: 'json',
            model: 'customData.datacardMapping',
            title: Translation.get('settings.dataCardMappingTitle', 'feed-management'),
            description: Translation.get('settings.dataCardMappingDescription', 'feed-management')
        });

        // When editing the dataset, you can also change the structure of the data (so which data type every key is)
        setup[0]['items'].push({
            type: 'json',
            model: 'structure',
            title: Translation.get('settings.recordStructureTitle', 'feed-management'),
            description: Translation.get('settings.recordStructureDescription', 'feed-management')
        });

        // When editing the datase, you can specify the fields to filter on if a structure is defined.
        if (dataSet && dataSet.structure && typeof dataSet.structure === 'object' && Object.keys(dataSet.structure).length > 0) {
            const filterFieldExclusions = FeedHelpers.getFilterFieldExclusions();
            const filterOptions = getPathes(dataSet.structure)
                .filter((item) => item.value === 'string' || item.value === 'number')
                .filter((item) => {
                    const checkArray = item.path.split('.');
                    return !checkArray.some((r) => filterFieldExclusions.indexOf(r) >= 0);
                })
                .map((item) => {
                    return {
                        value: item.path,
                        label: item.path
                    };
                });
            if (Array.isArray(filterOptions) && filterOptions.length > 0) {
                setup[0]['items'].push({
                    type: 'checkboxList',
                    model: 'filteringFields',
                    title: Translation.get('settings.filteringTitle', 'feed-management'),
                    description: Translation.get('settings.filteringDescription', 'feed-management'),
                    options: filterOptions
                });
            }
        }
    }
    const transform = (dataSet) => {
        const newData = { ...dataSet };

        if (newData.compoundIndex) {
            newData.compoundIndex = newData.compoundIndex.toString();
        }

        if (newData.filtering && newData.filtering.fields && Object.keys(newData.filtering.fields)) {
            newData.filters = [...Object.keys(newData.filtering.fields)];
            delete newData.filtering;
        } else if (newData.filtering) {
            delete newData.filtering;
        }
        return newData;
    };

    /**
     * Edit a data set
     * Update the data set on the API and if set, the filters via a seperate call.
     * @param {object} dataSet
     */
    const editDataset = (dataSet) => {
        const newData = { ...dataSet };

        if (newData.compoundIndex) {
            newData.compoundIndex = newData.compoundIndex.split(',');
            newData.compoundIndex = newData.compoundIndex.map((el) => el.trim());
        } else {
            newData.compoundIndex = ['id'];
        }

        delete newData.__v;
        return Promise.resolve(
            FeedRequest.patch(`dataset/${dataSet._id}`, newData)
                .then((data) => {
                    FeedHelpers.replaceDataSet(data.data);
                    onClose();
                    return true;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('settings.failedToEditDataset', 'feed-management'));
                    console.log('Failed to edit dataset', error);
                    throw new Error(error);
                })
        );
    };

    /**
     * Add a new dataset
     * @param {object} data - new data set
     */
    const addDataSet = (data) => {
        const newData = {
            ...data,
            ...data.customData,
            structure: {}
        };

        return Promise.resolve(
            FeedRequest.post('dataset', newData)
                .then((data) => {
                    FeedHelpers.setActiveDataSet(data.data);
                    callback(data.data._id);
                    return data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('settings.dataSetAddFailed', 'feed-management'));
                    console.error(error);
                    throw new Error(error);
                })
        );
    };

    /**
     * Determine wheter the submitting of the form should lead to add or edit.
     * @param {*} data
     * @returns
     */
    const handleSubmit = (data) => {
        if (dataSet._id) {
            return Promise.resolve(editDataset(data));
        } else {
            return Promise.resolve(addDataSet(data));
        }
    };

    return (
        <div className="feed-management-data-set-form">
            <FormFlow
                onSubmit={handleSubmit}
                data={transform({ ...dataSet })}
                setup={setup}
                onSubmitComplete={() => {}}
                submitButtonLabel={
                    dataSet && dataSet._id
                        ? Translation.get('settings.editDataset', 'feed-management')
                        : Translation.get('settings.addDataset', 'feed-management')
                }
                view={view}
                title={title}
                modalOpen={modalOpen}
                onCloseDialog={onClose}
                saveNotification={
                    dataSet && dataSet._id
                        ? Translation.get('settings.updatedDataset', 'feed-management')
                        : Translation.get('settings.addedDataset', 'feed-management')
                }
            />
        </div>
    );
};

export default FeedManagementDataSetFormSettings;
