import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import '../styles/main.scss';

/**
 * Display a data item key dependant on it's type and value.
 * @param {*} param0
 * @returns
 */
const DataItemKeyDisplay = ({ value, type, tableArrayValue }) => {
    // If the value is an array of objects of type text and a tableArrayValue is chosen, transform the array to a string with comma's
    if (type === 'text' && Array.isArray(value) && tableArrayValue) {
        return value.map((val, i) => (typeof val === 'object' ? `${val[tableArrayValue]}${value.length !== i + 1 ? ', ' : ''}` : val));
    }

    const youtubeParser = (value) => {
        if (!value || typeof value !== 'string') return false;

        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = value.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    };

    // If the value is a (valid) url, return a link
    if (type === 'text' && !!youtubeParser(value)) {
        const thumbnailUrl = `https://img.youtube.com/vi/${youtubeParser(value)}/hqdefault.jpg`;
        return (
            <div
                className="data-item-key-display__image-youtube"
                onClick={() => window.open(value, '_blank')}
                style={{ backgroundImage: `url('${thumbnailUrl}')` }}></div>
        );
    }

    // If the value is a (valid) url, return a link
    if (type === 'text' && FeedHelpers.isValidUrl(value)) {
        return (
            <a className="data-item-key-display__url" target="_blank" href={value}>
                {value}
            </a>
        );
    } else if (typeof value === 'object') {
        return <span>{JSON.stringify(value)}</span>;
    } else if (typeof value === 'boolean') {
        return <span>{value ? 'yes' : 'no'}</span>;
    } else if (type === 'text' && value) {
        return (
            <Tooltip title={`${value && value.length > 40 ? value : ''}`} placement="bottom-start">
                <span>{value}</span>
            </Tooltip>
        );
    } else if (type === 'text' && value) {
        return (
            <Tooltip title={`${value && value.length > 40 ? value : ''}`} placement="bottom-start">
                <span>{value}</span>
            </Tooltip>
        );
    } else if (type === 'image') {
        if (typeof value === 'object' && value.url) value = value.url;

        return <div className="data-item-key-display__image" style={{ backgroundImage: `url('${value}')` }}></div>;
    } else {
        return (
            <Tooltip title={`${value && value.length > 40 ? value : ''}`} placement="bottom-start">
                <span>{value}</span>
            </Tooltip>
        );
    }
};

export default DataItemKeyDisplay;
