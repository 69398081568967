import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { cloneDeep } from 'lodash';
import Dialog from 'components/ui-components/Dialog';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Content from './content';
import Sidebar from './sidebar';

import '../styles/main.scss';

/**
 * Display the dialog of the FeedSelector.
 */
class FeedSelectorDialog extends React.Component {
    static propTypes = {
        onMutation: PropTypes.func,
        feedId: PropTypes.string,
        feedTitle: PropTypes.string,
        display: PropTypes.oneOf(['grid', 'list'])
    };

    static defaultProps = {
        onMutation: () => { },
        feedId: null,
        feedTitle: null,
        display: null
    };

    /**
     * Initialize the component
     */
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            dataEntries: [],
            activeDataSet: {},
            activeDataSetId: null,
            filterSetup: [],
            filters: this.getInitialFilters([]),
            contentLoading: true,
            next: null,
            dialogHeight: null,
            hasMore: true,
            limit: 27,
            calculatedDisplay: 'grid'
        };

        this.dialogRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            try {
                const dialogHeight = this.dialogRef.current.clientHeight;
                this.setState({ dialogHeight });
            } catch (err) { }
        }, 20);

        this.getData();
    }

    /**
     * If pre-filters are defined, aply them if they match filters (and options) available in the filter setup.
     * @param {array} filterSetup
     * @returns {object} initial filter object
     */
    getInitialFilters = (filterSetup) => {
        const { preFilters } = this.props;
        if (!preFilters) return {};

        const newFilters = {};
        Object.entries(cloneDeep(preFilters)).forEach(([key, value]) => {
            if (key === 'searchTerm') {
                newFilters.searchTerm = value;
            } else {
                const thisFilter = filterSetup.find((filter) => filter.name === key);
                if (thisFilter && thisFilter.options) {
                    if (Array.isArray(value)) {
                        //It's a multi select filter.
                        const newValue = thisFilter.options.filter((option) => value.includes(option.key)).map((option) => option.key);
                        if (newValue.length > 0) {
                            newFilters[key] = newValue;
                        }
                    } else {
                        //It's a single select filter.
                        if (thisFilter.options.find((option) => option.key === value)) {
                            newFilters[key] = value;
                        }
                    }
                }
            }
        });
        return newFilters;
    };

    /**
     * Get the feed item id from a value object.
     * @param {object} item
     * @returns id
     */
    getItemId = (item) => {
        if (item._id) return item._id;
        if (item.originalRecord && item.originalRecord._id) return item.originalRecord._id;
        return false;
    };

    /**
     * Build an array of selected feed item id's.
     * @param {object} value
     */
    getSelectedItems = (value) => {
        const selectedItems = [];
        if (Array.isArray(value)) {
            value.forEach((item) => {
                const id = this.getItemId(item);
                if (id) selectedItems.push(id);
            });
        }
        return selectedItems;
    };

    // Get the dataset id's of the selected items and count how many items are selected per dataset.
    getDataSetsInSelection = (value) => {
        const dataSetsInSelection = {};
        if (Array.isArray(value)) {
            // Multi select scenario
            value.forEach((item) => {
                const datasetId = item.originalRecord?.datasetId;
                if (!datasetId) return;

                if (!dataSetsInSelection[datasetId]) {
                    dataSetsInSelection[datasetId] = 1;
                } else {
                    dataSetsInSelection[datasetId] = dataSetsInSelection[datasetId] + 1;
                }
            });
        } else if (value?.originalRecord?.datasetId) {
            // Single select scenario
            dataSetsInSelection[value.originalRecord.datasetId] = 1;
        }
        return dataSetsInSelection;
    };

    // Get the key of the object with the highest value.
    getKeyWithHighestValue(obj) {
        if (!obj) return null;
        if (Object.keys(obj).length === 0) return null;
        return Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b));
    }

    /**
     * Create the filter setup array for the generic filter
     * @param {object} dataset
     * @returns filterSetup
     */
    getFilterSetup = (dataset) => {
        const filterSetup = [];
        if (dataset?.filtering?.fields && Object.keys(dataset.filtering.fields) && Object.keys(dataset.filtering.fields).length > 0) {
            Object.entries(dataset.filtering.fields).forEach(([key, value]) => {
                const options = value.filter((x) => !!x).map((x) => ({ key: x, value: x }));
                if (Array.isArray(options) && options.length > 0) {
                    filterSetup.push({
                        name: key,
                        label: key,
                        type: 'selectMultiple',
                        options
                    });
                }
            });
        }

        return filterSetup;
    };

    /**
     * Load the list of datasets and set the first one as active.
     */
    getData = async () => {
        const { feedId, feedTitle } = this.props;
        let data = [];

        const list = await FeedRequest.get('dataset');
        if (list && list.data) {
            data = list.data;

            // Filter the available datasets if a filter prop is specified.
            if (feedId) {
                data = data.filter((x) => x._id === feedId);
            } else if (feedTitle) {
                data = data.filter((x) => x.customData && x.customData.title && x.customData.title.toLowerCase().includes(feedTitle.toLowerCase()));
            }

            this.setState({ data }, () => this.activateInitialDataset(data));
        }
    };

    /**
     * If a previous selection exists, set the first dataset that contains the highest selected items as active,
     * @param {array} data
     */
    activateInitialDataset = (data) => {
        const { value } = this.props;
        const dataSetsInSelection = this.getDataSetsInSelection(value);
        const activeDataSetId = this.getKeyWithHighestValue(dataSetsInSelection);
        const activeDataset = data.find((d) => d._id === activeDataSetId);

        if (activeDataset) {
            this.setDataset(activeDataset);
        } else {
            this.setDataset(data[0]);
        }
    };

    /**
     * Select an active dataset
     * @param {string} id _id key of the slected dataset
     */
    onSelectDataset = (id) => {
        const { data } = this.state;
        const activeDataSet = data.filter((item) => item._id === id)[0];
        this.setDataset(activeDataSet);
    };

    /**
     * Get data entries from API
     * @returns array of data entries
     */
    getDataEntries = async () => {
        const { activeDataSetId, limit, filters, next } = this.state;
        const filtering = { ...filters };

        let url = `dataset/${activeDataSetId}/item/search?limit=${limit}`;
        if (next) url = url + `&pageToken=${next}`;
        if (filtering.searchTerm && filtering.searchTerm.length > 0) {
            url = url + `&searchTerm=${filtering.searchTerm}`;
            delete filtering.searchTerm;
        }

        let feedList = [];

        try {
            feedList = await FeedRequest.post(url, { filtering });
        } catch (error) {
            SnackbarUtils.error('There was an error retrieving the dataset');
            this.setState({ contentLoading: false });
            return [];
        }

        if (feedList && feedList.data && feedList.data.data) {
            return feedList.data.data;
        } else {
            return [];
        }
    };

    /**
     * Get the next pagetoken to use in data entry API calls
     * @param {array} dataEntries
     * @returns
     */
    getNext = (dataEntries) => {
        if (dataEntries && dataEntries.length > 0) {
            return dataEntries[dataEntries.length - 1]['_id'];
        } else return null;
    };

    /**
     * Check if there is another page of data entries that can be fetched from the API
     * @param {array} dataEntries
     * @returns
     */
    getHasMore = (dataEntries) => {
        const { limit } = this.state;
        return dataEntries.length < limit ? false : true;
    };

    /**
     * Set a dataset as active and load it's entries.
     * @param {object} activeDataSet
     */
    setDataset = (activeDataSet) => {
        const activeDataSetId = activeDataSet?._id || null;
        const datacardMapping = activeDataSet?.customData?.datacardMapping || {};

        const filterSetup = this.getFilterSetup(activeDataSet);

        this.setState(
            {
                activeDataSetId,
                activeDataSet,
                datacardMapping,
                filterSetup,
                filters: this.getInitialFilters(filterSetup),
                next: null,
                hasMore: true,
                contentLoading: true
            },
            () => this.loadData()
        );
    };

    /**
     * Get the data entries from the api and set the display accordingly.
     */
    loadData = async () => {
        let { calculatedDisplay } = this.state;
        const { display } = this.props;
        let dataEntries = await this.getDataEntries();
        const next = this.getNext(dataEntries);
        const hasMore = this.getHasMore(dataEntries);

        // If no display type is specified, show grid for feeds with images.
        if (!!display && ['grid', 'list'].includes(display)) {
            calculatedDisplay = display;
        } else {
            calculatedDisplay = this.hasImages(dataEntries[0]) ? 'grid' : 'list';
        }

        dataEntries = this.addScheduleNameToDataEntries(dataEntries);

        this.setState({
            dataEntries,
            next,
            hasMore,
            calculatedDisplay,
            contentLoading: false
        });
    };

    /**
     * Load more items of the selected dataset
     */
    loadMoreData = async () => {
        const { dataEntries } = this.state;

        let newDataEntries = await this.getDataEntries();
        const next = this.getNext(newDataEntries);
        const hasMore = this.getHasMore(newDataEntries);

        newDataEntries = this.addScheduleNameToDataEntries(newDataEntries);

        this.setState({
            next,
            dataEntries: [...dataEntries, ...newDataEntries],
            hasMore
        });
    };

    /**
     * Set the filters recieved from the filter component in the state and execute filtering.
     * @param {object} newFilters
     */
    onChangeFilters = (newFilters) => {
        const { filters } = this.state;

        // Only continu if the newFilters are different from the existing ones
        if (isEqual(filters, newFilters)) return;

        this.setState(
            {
                filters: newFilters,
                next: null,
                hasMore: true,
                contentLoading: true
            },
            () => this.loadData()
        );
    };

    /**
     * Add or remove an item from the selected items list;
     * @param {object} item
     */
    onSelectDataCard = (item) => {
        const { datacardMapping } = this.state;
        this.props.onMutation({ ...item, datacardMapping });
    };

    /**
     * Check a feed item to find out of there will be an image in the mapped data.
     * @param {object} item
     * @returns true or false
     */
    hasImages = (item) => {
        const { datacardMapping } = this.state;

        if (item) {
            const mappedData = item.data ? FeedHelpers.mapDataForDisplay(item.data, datacardMapping) : {};
            if (mappedData.image) {
                return true;
            }
            return false;
        }
        return false;
    };

    /**
     * Enrich the dataentries with the name of the schedule they came from.
     * @param {*} dataEntries
     * @param {*} data
     * @returns array of enriched data entries
     */
    addScheduleNameToDataEntries = (dataEntries) => {
        const { data, activeDataSetId } = this.state;

        // Add the scheduleName to the data entries
        const newDataEntries = dataEntries.map((entry) => {
            let feedName;

            try {
                const dataSet = data.find((d) => d._id === activeDataSetId);
                const feed = dataSet.feeds.find((f) => (f.feedId = entry.feedId));
                feedName = feed.name;
            } catch (err) {
                console.log('Error in trying to get the schedule name');
            }

            return {
                ...entry,
                data: {
                    ...entry.data,
                    feedName
                }
            };
        });

        return newDataEntries;
    };

    render() {
        const { open, value, onClose } = this.props;
        const {
            data,
            dataEntries,
            activeDataSetId,
            activeDataSet,
            filterSetup,
            filters,
            calculatedDisplay,
            contentLoading,
            dialogHeight,
            hasMore,
            datacardMapping
        } = this.state;

        return (
            <Dialog title={'Feed selector'} fullWidth={true} open={open} onClose={onClose} fixedHeightPaperScrollPaper={true}>
                <div className="feed-selector-dialog" ref={this.dialogRef}>
                    {data && data.length > 1 && (
                        <div className="feed-selector-dialog__sidebar">
                            <Sidebar
                                activeDataSetId={activeDataSetId}
                                dataSetsInSelection={this.getDataSetsInSelection(value)}
                                data={data}
                                onSelect={this.onSelectDataset}></Sidebar>
                        </div>
                    )}
                    <div className="feed-selector-dialog__content">
                        <Content
                            activeDataSet={activeDataSet}
                            filterSetup={filterSetup}
                            filters={filters}
                            searchTerm={filters.searchTerm}
                            dataEntries={dataEntries}
                            datacardMapping={datacardMapping}
                            isLoading={contentLoading}
                            dialogHeight={dialogHeight}
                            hasMore={hasMore}
                            selectedItems={this.getSelectedItems(value)}
                            display={calculatedDisplay}
                            loadMore={this.loadMoreData}
                            onSelectDataCard={this.onSelectDataCard}
                            onChangeFilters={this.onChangeFilters}
                            columns={data && data.length > 1 ? 3 : 4}></Content>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default FeedSelectorDialog;
