import React, { useState, useEffect, useRef } from 'react';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';

/**
 * Get the right text to display.
 * @param {string} schedule
 * @param {boolean} autoPublish
 * @param {boolean} updatesEnabled
 * @param {boolean} autoPublishEnabled
 * @param {boolean} isManual
 * @param {string} latestUpdateDate
 * @returns text string
 */
const getUpdateText = (schedule, autoPublish, updatesEnabled, autoPublishEnabled, isManual) => {
    if (isManual) {
        if (autoPublish && autoPublishEnabled && schedule !== FeedHelpers.neverCron) {
            return Translation.get('feeds.feedUpdates.autopublishManualChanges', 'feed-management', {
                cronString: FeedHelpers.timeToCron(schedule)
            });
        } else {
            return Translation.get('feeds.feedUpdates.feedWillNotAutoPublish', 'feed-management');
        }
    } else {
        if (autoPublish) {
            if (updatesEnabled && autoPublishEnabled && schedule !== FeedHelpers.neverCron) {
                return Translation.get('feeds.feedUpdates.feedWillAutoPublish', 'feed-management', {
                    cronString: FeedHelpers.timeToCron(schedule)
                });
            } else if (updatesEnabled && !autoPublishEnabled && schedule !== FeedHelpers.neverCron) {
                return Translation.get('feeds.feedUpdates.feedWillUpdate', 'feed-management', {
                    cronString: FeedHelpers.timeToCron(schedule)
                });
            } else {
                return Translation.get('feeds.feedUpdates.feedWillNotUpdate', 'feed-management');
            }
        } else {
            if (updatesEnabled && schedule !== FeedHelpers.neverCron) {
                return Translation.get('feeds.feedUpdates.feedWillUpdate', 'feed-management', {
                    cronString: FeedHelpers.timeToCron(schedule)
                });
            } else {
                return Translation.get('feeds.feedUpdates.feedWillNotUpdate', 'feed-management');
            }
        }
    }
};

/**
 * Display the textual part of the update information of a feed.
 * @param {*} param0
 * @returns
 */
const FeedUpdatesText = ({ schedule, autoPublish, updatesEnabled, autoPublishEnabled, isManual }) => {
    const [text, setText] = useState('');
    const timerRef = useRef(null);
    const scheduleRef = useRef(schedule);
    const updatesEnabledRef = useRef(updatesEnabled);
    const autoPublishRef = useRef(autoPublish);
    const autoPublishEnabledRef = useRef(autoPublishEnabled);

    // Handel changes in text input
    useEffect(() => {
        scheduleRef.current = schedule;
        updatesEnabledRef.current = updatesEnabled;
        autoPublishRef.current = autoPublish;
        autoPublishEnabledRef.current = autoPublishEnabled;

        setText(getUpdateText(scheduleRef.current, autoPublishRef.current, updatesEnabledRef.current, autoPublishEnabledRef.current, isManual));
    }, [schedule, autoPublish, updatesEnabled, autoPublishEnabled]);

    // Startup periodic updating of text.
    useEffect(() => {
        repeat();
        return () => clearTimeout(timerRef.current);
    }, []);

    // Update text periodically
    const repeat = () => {
        setText(getUpdateText(scheduleRef.current, autoPublishRef.current, updatesEnabledRef.current, autoPublishEnabledRef.current, isManual));
        timerRef.current = setTimeout(() => repeat(), 1000);
    };

    return (
        <>
            <Icon fontSize="inherit">update</Icon> {text}
        </>
    );
};

export default FeedUpdatesText;
