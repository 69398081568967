import React, { useState, useEffect, useRef } from 'react';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';

/**
 * Return the formatted text about the latest update.
 * @param {object} dataSet
 * @param {boolean} manual
 * @returns text
 */
const getUpdateText = (dataSet, manual) => {
    if (manual) {
        return FeedHelpers.formatLatestUpdateDate(
            dataSet && dataSet.metadata && dataSet.metadata.lastManualChange,
            Translation.get('feedHelpers.noManualUpdate', 'feed-management')
        );
    } else {
        return FeedHelpers.formatLatestUpdateDate(dataSet && dataSet.latestUpdate);
    }
};

/**
 * Display the time since the last update and keep it up-to-date with an interval.
 * @param {*} param0
 * @returns
 */
const FeedStatisticsLatestUpdate = ({ dataSet, manual }) => {
    const [text, setText] = useState(null);
    const timerRef = useRef(null);
    const dataSetRef = useRef(dataSet);

    // Handel changes in text input
    useEffect(() => {
        dataSetRef.current = dataSet;

        setText(getUpdateText(dataSetRef.current, manual));
    }, [dataSet]);

    // Startup periodic updating of text.
    useEffect(() => {
        repeat();
        return () => clearTimeout(timerRef.current);
    }, []);

    // Update text periodically
    const repeat = () => {
        setText(getUpdateText(dataSetRef.current, manual));
        timerRef.current = setTimeout(() => repeat(), 1000);
    };

    return (
        <React.Fragment>
            <span>{text}</span>
        </React.Fragment>
    );
};

export default FeedStatisticsLatestUpdate;
