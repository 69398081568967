import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import Checkbox from 'components/ui-components-v2/Checkbox';
import DataItemKeyDisplay from '../../DataItemKeyDisplay';
import '../styles/data-row.scss';

/**
 * Display the contents of a feed item in a row for use in the feed selector.
 * @param {*} param0
 * @returns
 */
const DataRow = ({ selected, structure, item, onSelect }) => {
    return (
        <TableRow className="feed-management-data-row" onClick={() => onSelect(item)}>
            <TableCell className="feed-management-data-row__cell">
                <Checkbox checked={selected} />
            </TableCell>
            {structure.map((structureItem, indexStructure) => {
                if (!structureItem.hideColumn) {
                    return (
                        <TableCell key={indexStructure} className="feed-management-data-row__cell">
                            <DataItemKeyDisplay
                                type={structureItem.type}
                                tableArrayValue={structureItem.tableArrayValue}
                                value={FeedHelpers.checkDotNotation(item.data, structureItem['key'])}
                            />
                        </TableCell>
                    );
                } else return null;
            })}
        </TableRow>
    );
};

DataRow.defaultProps = {
    onSelect: () => {}
};

export default DataRow;
