import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/copy.scss';

/**
 * Display the copy / text part of a feed.
 * @param {*} param0
 * @returns
 */
const FeedCopy = ({ name, url }) => {
    return (
        <div className="feed-management__feed-copy">
            <div className="feed-management__feed-copy__name">{name}</div>
            {url && (
                <div className="feed-management__feed-copy__url">
                    <Icon fontSize="inherit">language</Icon>
                    <span className="feed-management__feed-copy__url__text">{url}</span>
                </div>
            )}
        </div>
    );
};

export default FeedCopy;
