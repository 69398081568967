import React from 'react';
import FeedTypeIcon from '../../FeedTypeIcon';
import '../styles/container.scss';

/**
 * Container with necessary styling to display a feed
 * @param {*} param0
 * @returns
 */
const FeedContainer = ({ type, children }) => {
    return (
        <div className="feed-management__feed-container">
            <FeedTypeIcon type={type} className="feed-management__feed-container__icon" />
            <div className="feed-management__feed-container__content ">
                <div className="feed-management__feed-container__top">{children[0] ? children[0] : children}</div>
                {children[1] && <div className="feed-management__feed-container__bottom">{children[1]}</div>}
            </div>
        </div>
    );
};

export default FeedContainer;
