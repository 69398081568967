import React, { useState } from 'react';
import classNames from 'classnames';
import Avatar from '@mui/material/Avatar';
import Badge from 'components/ui-components-v2/Badge';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import Icon from 'components/ui-components-v2/Icon';
import SearchField from 'components/ui-components/SearchField';
import Translation from 'components/data/Translation';

import '../styles/sidebar.scss';

/**
 * Display teh side bar of the FeedSelecto dialog.
 * Used to select a data set if there are more then one.
 */
const FeedSelectorSidebar = ({ onSelect, activeDataSetId, dataSetsInSelection, data }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dataSets, setDataSets] = useState(data);

    const onSearch = (_, term) => {
        setSearchTerm(term);
        setDataSets(
            data.filter((item) => {
                const title = item.customData && item.customData.title ? item.customData.title : '';
                return title.toLowerCase().includes(term.toLowerCase());
            })
        );
    };

    return (
        <div className="feed-selector-sidebar">
            {data.length > 8 && (
                <SearchField
                    searchTerm={searchTerm}
                    onChange={onSearch}
                    searchPlaceholder={Translation.get('feedSelectorDialog.searchPlaceholder', 'feed-management')}
                    className="feed-selector-sidebar__search"
                />
            )}
            {dataSets.map((item) => (
                <div
                    key={item._id}
                    className={classNames('feed-selector-sidebar__item', { 'feed-selector-sidebar__item--active': activeDataSetId === item._id })}
                    onClick={() => onSelect(item._id)}>
                    <Badge badgeContent={dataSetsInSelection[item._id] || 0} color={activeDataSetId === item._id ? 'secondary' : 'primary'}>
                        <Avatar
                            variant="rounded"
                            className={classNames('feed-selector-sidebar__item__avatar', {
                                'feed-selector-sidebar__item__avatar--active': activeDataSetId === item._id
                            })}>
                            <Icon
                                className={classNames('feed-selector-sidebar__item__icon', {
                                    'feed-selector-sidebar__item__icon--active': activeDataSetId === item._id
                                })}>
                                storage
                            </Icon>
                        </Avatar>
                    </Badge>
                    <div className="feed-selector-sidebar__item__copy">
                        <div className="feed-selector-sidebar__item__copy__name">{item.customData?.title}</div>
                        <div className="feed-selector-sidebar__item__copy__subtitle">
                            {Translation.get('feedSelectorDialog.datasetCounts', 'feed-management', {
                                feedCount: item.feeds?.length || 0,
                                itemCount: item.metadata?.itemCount
                            })}
                        </div>
                    </div>
                </div>
            ))}
            {!dataSets.length && (
                <EmptyState
                    size="large"
                    className="feed-management-activity__empty-state"
                    primaryText={Translation.get('overview.noDataSetFound', 'feed-management')}
                    illustration={<Illustration size="large" illustration="fallback" />}
                />
            )}
        </div>
    );
};

export default FeedSelectorSidebar;
