import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Icon from 'components/ui-components-v2/Icon';

import '../styles/latest-publish.scss';
import Translation from 'components/data/Translation';

/**
 * Return a text with the relative time from the latest update.
 * @param {object} latestPublish
 * @returns text
 */
const getUpdateText = (latestPublish) => {
    if (!!latestPublish && latestPublish.date) {
        return moment(latestPublish.date).fromNow();
    }
    return '';
};

/**
 * Display the formtted text about the latest autopublish action.
 * @param {*} param0
 * @returns
 */
const FeedStatisticsLatestPublish = ({ latestPublish, campaignId }) => {
    const [text, setText] = useState(null);
    const timerRef = useRef(null);
    const latestPublishRef = useRef(latestPublish);

    // Handel changes in text input
    useEffect(() => {
        latestPublishRef.current = latestPublish;

        setText(getUpdateText(latestPublishRef.current));
    }, [latestPublish]);

    // Startup periodic updating of text.
    useEffect(() => {
        repeat();
        return () => clearTimeout(timerRef.current);
    }, []);

    // Update text periodically
    const repeat = () => {
        if (latestPublishRef.current) setText(getUpdateText(latestPublishRef.current));
        timerRef.current = setTimeout(() => repeat(), 1000);
    };

    return (
        <React.Fragment>
            {text ? (
                <Link
                    to={`/editor/${campaignId}/publish`}
                    onClick={(e) => e.stopPropagation()}
                    className={classNames('feed-management-feed-statistics-latest-publish__link', {
                        'feed-management-feed-statistics-latest-publish__link--error':
                            latestPublish && latestPublish.errorMessage && latestPublish.errorMessage.length > 0
                    })}>
                    <Icon fontSize="inherit" className="feed-management-feed-statistics-latest-publish__icon">
                        update
                    </Icon>
                    <span>{text}</span>
                </Link>
            ) : (
                <React.Fragment>
                    <Icon fontSize="inherit" className="feed-management-feed-statistics-latest-publish__icon">
                        rocket_launch
                    </Icon>
                    <span>{Translation.get('feedStatistics.notPublishedYet', 'feed-management')}</span>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default FeedStatisticsLatestPublish;
