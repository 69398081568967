import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FeedContainer from './container';
import FeedCopy from './copy';
import FeedActions from './actions';
import FeedUpdates from './updates';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import FeedStatistics from '../../FeedStatistics';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import '../styles/manual.scss';
import Translation from 'components/data/Translation';

// Display a manual data set as a custom kind of feed.
const FeedManual = ({ dataSet = {}, onGetDatasetItems }) => {
    const history = useHistory();
    const [showRemoveDataSet, setShowRemoveDataSet] = useState(false);

    // Remove current data set
    const removeDataSet = () => {
        FeedRequest.delete(`dataset/${dataSet._id}`)
            .then(() => {
                SnackbarUtils.success(Translation.get('dataSet.removedDataSet', 'feed-management'));
                setShowRemoveDataSet(false);

                FeedHelpers.loadData();
                history.push('/feed-management');
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.removedDataSetFailed', 'feed-management'));
                console.log('Failed to remove data set', error);
            });
    };

    if (!dataSet) return <React.Fragment></React.Fragment>;

    return (
        <React.Fragment>
            <FeedContainer type="custom">
                <React.Fragment>
                    <FeedCopy name={Translation.get('dataSet.dataSetCustom', 'feed-management')} />
                    <FeedActions
                        dataSet={dataSet}
                        autoPublish={dataSet.autoPublish}
                        showRemoveFeed={showRemoveDataSet}
                        onSetShowRemoveFeed={setShowRemoveDataSet}
                        onGetDatasetItems={onGetDatasetItems}
                    />
                </React.Fragment>
                {dataSet.autoPublish && (
                    <React.Fragment>
                        <FeedUpdates feed={false} dataSet={dataSet} />
                        <FeedStatistics dataSet={dataSet} />
                    </React.Fragment>
                )}
            </FeedContainer>
            {showRemoveDataSet && (
                <ConfirmDialog
                    description={Translation.get('feeds.confirmDialogRemoveDataSetWarning', 'feed-management', {
                        name: dataSet.customData && dataSet.customData.title
                    })}
                    onConfirm={() => removeDataSet()}
                    open={true}
                    onClose={() => setShowRemoveDataSet(false)}
                />
            )}
        </React.Fragment>
    );
};

export default FeedManual;
