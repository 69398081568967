import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import ItemPreview from '../../ItemPreview';
import '../styles/main.scss';

/**
 * Get the second line to show in the data card.
 * @param {object} mappedData
 * @returns
 */
const getSecondLine = (mappedData) => {
    if (mappedData.feedName && mappedData.feedName.trim().length > 0) return mappedData.feedName;
    if (mappedData.subtitle && mappedData.subtitle.trim().length > 0) return mappedData.subtitle;
    if (mappedData.label && mappedData.label.trim().length > 0) return mappedData.label;
    if (mappedData.category && mappedData.category.trim().length > 0) return mappedData.category;
    return null;
};

/**
 * Show the selected feed items in a compact form. For use in the FeedSelector inputs.
 */
const DataItem = ({ id, data, mapping, className, onEditItem, onRemoveItem }) => {
    const [mappedData, setMappedData] = useState(FeedHelpers.mapDataForDisplay(data, mapping));
    const [secondLine, setSecondLine] = useState(getSecondLine(mappedData));

    useEffect(() => {
        const newMappedData = FeedHelpers.mapDataForDisplay(data, mapping);
        setMappedData(newMappedData);
        setSecondLine(getSecondLine(newMappedData));
    }, [data, mapping]);

    return (
        <div className={classNames('feed-management-data-item', className)}>
            <div className="feed-management-data-item__left">
                {!!mappedData.image && mappedData.image.length > 0 && (
                    <div className="feed-management-data-item__thumb" style={{ backgroundImage: `url(${mappedData.image})` }}></div>
                )}
                <div className="feed-management-data-item__content">
                    <div className="feed-management-data-item__content__title">{mappedData.title}</div>
                    {!!secondLine && secondLine.length > 0 && <div className="feed-management-data-item__content__subtitle">{secondLine}</div>}
                </div>
            </div>
            <div className="feed-management-data-item__right">
                <ItemPreview item={{ data }}>
                    <Tooltip title="More information">
                        <IconButton size="small">
                            <Icon>info</Icon>
                        </IconButton>
                    </Tooltip>
                </ItemPreview>
                {!!onEditItem && typeof onEditItem === 'function' && (
                    <Tooltip title="Edit item">
                        <IconButton onClick={() => onEditItem(id)} size="small">
                            <Icon>edit</Icon>
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Remove item">
                    <IconButton onClick={() => onRemoveItem(id)} size="small">
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};

DataItem.defaultProps = {
    onRemoveItem: () => {}
};

export default DataItem;
