import React, { useState, useEffect } from 'react';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Switch from 'components/ui-components-v2/Switch';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import FeedUpdatesText from './updates-text';

import '../styles/updates.scss';

/**
 * Display the update an autopublish status and switches of a feed.
 * @param {*} param0
 * @returns
 */
const FeedUpdates = ({ feed, dataSet }) => {
    const [updatesEnabled, setUpdatesEnabled] = useState(feed && feed.isEnabled);
    const [autoPublishEnabled, setAutoPublishEnabled] = useState(dataSet.autoPublish && dataSet.autoPublish.enabled);

    // On the first render (when you just created the feed) the feed.isEnabled can be undefined, so this makes sure that the value gets updated
    useEffect(() => {
        setUpdatesEnabled(feed.isEnabled);
    }, [feed.isEnabled]);

    useEffect(() => {
        setAutoPublishEnabled(dataSet.autoPublish && dataSet.autoPublish.enabled);
    }, [dataSet.autoPublish]);

    /** Enable or disable the updating schedule of this feed
     * @param {string} action - 'enable' or 'disable'
     */
    const handleUpdates = (e) => {
        const newStatus = e.target.checked;
        setUpdatesEnabled(newStatus);
        FeedRequest.patch(`dataset/${dataSet._id}/feed/${feed.feedId}`, { isEnabled: newStatus })
            .then(() => {
                // Reload the entire feed list after editing
                SnackbarUtils.success(
                    newStatus
                        ? Translation.get('feeds.feedUpdates.enabled', 'feed-management')
                        : Translation.get('feeds.feedUpdates.disabled', 'feed-management')
                );
                FeedHelpers.reloadDataSet(dataSet._id);
            })
            .catch(() => {
                setUpdatesEnabled(feed.isEnabled);
                SnackbarUtils.error(Translation.get('feedUpdatingFailed', 'feed-management'));
            });
    };

    /**
     * Set enabled property of the autoPublish object
     * @param {boolean} value
     */
    const handleAutoPublish = (e) => {
        const newStatus = e.target.checked;
        setAutoPublishEnabled(newStatus);
        FeedRequest.patch(`dataset/${dataSet._id}/`, {
            autoPublish: {
                enabled: newStatus
            }
        })
            .then(() => {
                // Reload the data set after editing
                SnackbarUtils.success(
                    newStatus
                        ? Translation.get('feeds.feedUpdates.publishEnabled', 'feed-management')
                        : Translation.get('feeds.feedUpdates.publishDisabled', 'feed-management')
                );
                FeedHelpers.reloadDataSet(dataSet._id);
            })
            .catch((error) => {
                console.error(error);
                SnackbarUtils.error(Translation.get('feeds.feedUpdates.publishUpdateFailed', 'feed-management'));
            });
    };

    /**
     * Derive the right update shedule for this case
     * @param {object} feed
     * @param {object} dataSet
     * @param {boolean} autoPublishEnabled
     * @returns schedule string
     */
    const getSchedule = (feed, dataSet, autoPublishEnabled) => {
        if (feed && feed.schedule) {
            return feed.schedule;
        } else if (autoPublishEnabled && dataSet.autoPublish) {
            return dataSet.autoPublish.schedule;
        }
        return null;
    };

    return (
        <div className="feed-management__feed-updates">
            {!!feed && feed.schedule !== FeedHelpers.neverCron && (
                <React.Fragment>
                    <div className="feed-management__feed-updates__label">{Translation.get('feeds.feedUpdates.feedUpdateTitle', 'feed-management')}</div>
                    <Switch checked={updatesEnabled} onChange={(e) => handleUpdates(e)} name="updates" className="feed-management__feed-updates__switch" />
                </React.Fragment>
            )}
            {dataSet.autoPublish && dataSet.autoPublish.schedule !== FeedHelpers.neverCron && (
                <React.Fragment>
                    <div className="feed-management__feed-updates__label">{Translation.get('feeds.feedUpdates.autoPublishTitle', 'feed-management')}</div>
                    <Switch
                        checked={autoPublishEnabled}
                        onChange={(e) => handleAutoPublish(e)}
                        name="autopublish"
                        className="feed-management__feed-updates__switch"
                    />
                </React.Fragment>
            )}
            <div className="feed-management__feed-updates__props">
                <FeedUpdatesText
                    schedule={getSchedule(feed, dataSet, autoPublishEnabled)}
                    updatesEnabled={updatesEnabled}
                    autoPublish={!!dataSet.autoPublish}
                    autoPublishEnabled={autoPublishEnabled}
                    isManual={dataSet.customData && dataSet.customData.isManual}
                />
            </div>
            {!!feed && feed.schedule !== FeedHelpers.neverCron && (
                <div className="feed-management__feed-updates__props">
                    <Icon fontSize="inherit">refresh</Icon>
                    {feed.updateAction === 'append'
                        ? Translation.get('feeds.feedUpdates.append', 'feed-management')
                        : Translation.get('feeds.feedUpdates.replace', 'feed-management')}
                </div>
            )}
        </div>
    );
};

export default FeedUpdates;
