import React, { useState } from 'react';
import FeedFormDialog from './dialog';

/**
 * Open the feed form by clicking on the child of the component.
 * @param {*} param0
 * @returns
 */
const FeedForm = ({ dataSetId, feed = {}, inCampaign = false, callback = () => {}, children, setup, structure = [], view = 'modal' }) => {
    const [showDialog, setShowDialog] = useState(false);

    /** Add the open dialog action to the child of this component
     * @param {child} data -
     */
    const childrenWithProps = (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        const props = {
            onClick: () => {
                setShowDialog(true);
            }
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
        return child;
    };

    return (
        <React.Fragment>
            {childrenWithProps(children)}
            {showDialog && (
                <FeedFormDialog
                    dataSetId={dataSetId}
                    feed={feed}
                    inCampaign={inCampaign}
                    callback={callback}
                    setup={setup}
                    structure={structure}
                    onClose={() => setShowDialog(false)}
                    view={view}
                />
            )}
        </React.Fragment>
    );
};

export default FeedForm;
