import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from 'components/ui-components-v2/Tooltip';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import Columns from 'components/ui-base/Columns';
import User from 'components/data/User';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FeedHelpers from 'components/data/FeedManagementHelpers';
import GenericFilter from 'components/ui-base/GenericFilter';
import DataCard from '../../DataCard';
import { FeedStatisticsIcons } from '../../FeedStatistics';
import DataRow from './data-row';
import '../styles/content.scss';

/**
 * Diplay the content part of the FeedSelector dialog.
 */
class FeedSelectorContent extends React.Component {
    constructor(props) {
        super(props);

        this.scrollParentRef = React.createRef();

        const structure = FeedHelpers.getStructure(props.activeDataSet, props.structure);
        const displayTypes = FeedHelpers.getDisplayTypes(structure);

        this.state = {
            structure,
            displayTypes
        };
    }

    static propTypes = {
        activeDataSetId: PropTypes.string
    };

    static defaultProps = {
        activeDataSetId: null,
        selectedItems: []
    };

    componentDidUpdate(prevProps) {
        const { activeDataSet } = this.props;
        if (activeDataSet && prevProps.activeDataSet !== activeDataSet) {
            // et the structure and the display types for the currently loaded data set.
            const structure = FeedHelpers.getStructure(activeDataSet, this.props.structure);
            const displayTypes = FeedHelpers.getDisplayTypes(structure);
            this.setState({
                structure,
                displayTypes
            });
        }
    }

    /**
     * Copy the active data set id to the users clipboard.
     * @param {string} id
     */
    copyToClipboard = async (id) => {
        try {
            await navigator.clipboard.writeText(id);
            SnackbarUtils.success('Feed ID copied to clipboard.');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    render() {
        const {
            activeDataSet = {},
            dataEntries = [],
            display,
            columns = 3,
            filterSetup = [],
            filters = {},
            onChangeFilters,
            isLoading,
            loadMore,
            dialogHeight,
            hasMore,
            selectedItems,
            onSelectDataCard,
            datacardMapping
        } = this.props;
        const { structure, displayTypes } = this.state;

        // Calculate the max height of the infinite scroll parent
        const dataCardsHeight = dialogHeight - 80 - 56;

        return (
            <div className="feed-selector-content">
                {!!activeDataSet && (
                    <div className="feed-selector-content__header">
                        <div
                            className={classNames({
                                'feed-selector-content__header__one': columns === 3,
                                'feed-selector-content__header__one--wide': columns === 4
                            })}>
                            <div className="feed-selector-content__header__name">
                                <Tooltip title={`Click to copy feed ID: ${activeDataSet._id}`}>
                                    <span onClick={() => this.copyToClipboard(activeDataSet._id)}>
                                        {activeDataSet.customData && activeDataSet.customData.title}
                                    </span>
                                </Tooltip>
                            </div>
                            <div className="feed-selector-content__header__subtitle">
                                <span>Entries:</span> {activeDataSet.metadata && activeDataSet.metadata.itemCount}
                            </div>
                        </div>

                        {activeDataSet.latestUpdate && (
                            <div
                                className={classNames({
                                    'feed-selector-content__header__two': columns === 3,
                                    'feed-selector-content__header__two--wide': columns === 4
                                })}>
                                {activeDataSet.latestUpdate.date && (
                                    <div className="feed-selector-content__header__subtitle">
                                        <span>Latest update:</span> {FeedHelpers.formatLatestUpdateDate(activeDataSet.latestUpdate)}
                                    </div>
                                )}
                                <div className="feed-selector-content__header__statistics">
                                    <FeedStatisticsIcons latestUpdate={activeDataSet.latestUpdate} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="feed-selector-content__searchbar">
                    <GenericFilter
                        key={`filters-${activeDataSet._id}`}
                        inDialog
                        searchField
                        showHorizontal={1}
                        filterSetup={filterSetup}
                        filters={filters}
                        searchTerm={filters.searchTerm}
                        canCopyFilters={User.get('type') === 'superadmin' && filters && Object.keys(filters) && Object.keys(filters).length > 0}
                        onMutation={onChangeFilters}
                    />
                </div>
                <div className="feed-selector-content__data-cards" style={{ maxHeight: dataCardsHeight }} ref={this.scrollParentRef}>
                    {isLoading && (
                        <div className="feed-selector-content__data-cards__loading">
                            <CircularProgress color="primary" />
                        </div>
                    )}
                    {!isLoading && dataEntries && dataEntries.length === 0 && <div className="feed-selector-content__data-cards__empty">No entries found</div>}
                    {!isLoading && dataEntries && dataEntries.length > 0 && (
                        <React.Fragment>
                            <InfiniteScroll
                                loadMore={loadMore}
                                hasMore={hasMore}
                                getScrollParent={() => this.scrollParentRef.current}
                                initialLoad={false}
                                loader={
                                    <React.Fragment key={0}>
                                        <div className="asset-library-list__loader">
                                            <CircularProgress />
                                        </div>
                                    </React.Fragment>
                                }
                                useWindow={false}>
                                {display === 'grid' ? (
                                    <Columns count={columns}>
                                        {dataEntries.map((item, index) => (
                                            <div className="feed-selector-content__data-cards__card" key={index}>
                                                <DataCard
                                                    selected={selectedItems.includes(item._id)}
                                                    item={item}
                                                    mapping={datacardMapping || null}
                                                    onSelect={onSelectDataCard}
                                                />
                                            </div>
                                        ))}
                                    </Columns>
                                ) : (
                                    <Table stickyHeader size="small" className="feed-selector-content__table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                {structure.map((item, i) => {
                                                    if (!item.hideColumn) {
                                                        return (
                                                            <TableCell key={i} className="feed-selector-content__table__header">
                                                                {item.title}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {dataEntries.map((item, index) => (
                                                <DataRow
                                                    key={index}
                                                    selected={selectedItems.includes(item._id)}
                                                    displayTypes={displayTypes}
                                                    structure={structure}
                                                    item={item}
                                                    onSelect={onSelectDataCard}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                            </InfiniteScroll>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

export default FeedSelectorContent;
