import React from 'react';
import FeedContainer from './container';
import FeedCopy from './copy';
import FeedActions from './actions';
import FeedUpdates from './updates';
import FeedStatistics from '../../FeedStatistics';
import '../styles/main.scss';

/**
 * Display a feed
 * @param {*} param0
 * @returns
 */
const Feed = ({ feed = {}, dataSet, feedFormSetup, onGetDatasetItems }) => {
    return (
        <React.Fragment>
            <FeedContainer type={feed.type}>
                <React.Fragment>
                    <FeedCopy name={feed.name} url={feed.url} />
                    <FeedActions dataSet={dataSet} feed={feed} feedFormSetup={feedFormSetup} onGetDatasetItems={onGetDatasetItems} />
                </React.Fragment>
                <React.Fragment>
                    <FeedUpdates dataSet={dataSet} feed={feed} />
                    <FeedStatistics dataSet={dataSet} />
                </React.Fragment>
            </FeedContainer>
        </React.Fragment>
    );
};

export default Feed;
