import React, { useState } from 'react';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';

/**
 * Display a dialog with data set remove form and a child that can be clicked to open it.
 * @param {*} param0
 * @returns
 */
const FeedManagerDataSetRemoveForm = ({ dataSet, children, callback = () => {} }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [waiting, setWaiting] = useState(false);

    /**
     * Remove the currently loaded data set
     */
    const removeDataset = (e) => {
        e.stopPropagation();
        setWaiting(true);
        FeedRequest.delete(`dataset/${dataSet._id}`)
            .then(() => {
                SnackbarUtils.success(Translation.get('editor.removeForm.removedDataset', 'feed-management'));
                setShowDialog(false);
                setWaiting(false);
                FeedHelpers.setActiveDataSet(null);
                FeedHelpers.setLoading(true);
                FeedHelpers.loadData();
                callback();
            })
            .catch((error) => {
                SnackbarUtils.error(Translation.get('feeds.removedDataSetFailed', 'feed-management'));
                console.log('Failed to remove data set', error);
                setShowDialog(false);
                setWaiting(false);
            });
    };

    /**
     * Add the open dialog event to the child.
     * @param {*} child
     */
    const childrenWithProps = (child) => {
        const props = {
            onClick: (e) => {
                e.stopPropagation();
                setShowDialog(true);
            }
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
        return child;
    };

    return (
        <React.Fragment>
            {childrenWithProps(children)}
            {showDialog && (
                <ConfirmDialog
                    open={true}
                    title={Translation.get('editor.removeForm.removeDataset', 'feed-management')}
                    description={
                        dataSet.customData && dataSet.customData.title
                            ? Translation.get('editor.removeForm.removeWarning', 'feed-management', {
                                  customDataTitle: dataSet.customData.title
                              })
                            : Translation.get('editor.removeForm.removeWarningSimple', 'feed-management')
                    }
                    waiting={waiting}
                    onConfirm={(e) => removeDataset(e)}
                    onClose={() => setShowDialog(false)}
                />
            )}
        </React.Fragment>
    );
};

export default FeedManagerDataSetRemoveForm;
