import React, { useState, useEffect, useRef } from 'react';
import EditorData from 'components/editor-data/EditorData';
import FormFlow from 'components/ui-base/FormFlow';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';

import '../styles/dialog.scss';

const getFormSetup = (setup, dataSetId, structure = []) => {
    // If there is a form setup given in the props, use that one. Otherwise, use the default form setup
    let formSetup;

    if (setup) {
        formSetup = setup;
    } else {
        formSetup = [
            {
                items: [
                    {
                        type: 'select',
                        model: 'type',
                        title: Translation.get('feeds.addForm.feedType', 'feed-management'),
                        options: FeedHelpers.feedTypes
                    },
                    {
                        type: 'alertBox',
                        value: Translation.get('feeds.addForm.spreadsheetAlert', 'feed-management'),
                        condition: "data.type === 'spreadsheet'"
                    },
                    {
                        type: 'text',
                        model: 'url',
                        startIcon: 'link',
                        title: Translation.get('feeds.addForm.url', 'feed-management'),
                        validators: 'url'
                    },
                    {
                        type: 'subSection',
                        title: Translation.get('feeds.addForm.viewAdvancedOptions', 'feed-management'),
                        items: [
                            {
                                type: 'text',
                                model: 'basePath',
                                title: Translation.get('feeds.addForm.xmlPath', 'feed-management'),
                                condition: "data.type=='xml'"
                            },
                            {
                                type: 'select',
                                model: 'schedule',
                                title: Translation.get('feeds.addForm.scheduleUpdate', 'feed-management'),
                                options: FeedHelpers.feedCrons
                            },

                            {
                                type: 'radioGroup',
                                model: 'removeNonExisting',
                                title: Translation.get('feeds.addForm.fetchingFeedBehaviour', 'feed-management'),
                                options: [
                                    {
                                        value: 'true',
                                        label: Translation.get('feeds.addForm.fetchingFeedBehaviourOptions.remove', 'feed-management')
                                    },
                                    {
                                        value: 'false',
                                        label: Translation.get('feeds.addForm.fetchingFeedBehaviourOptions.keep', 'feed-management')
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                model: 'firstRow',
                                title: Translation.get('feeds.addForm.columns', 'feed-management'),
                                options: [
                                    {
                                        id: 'firstRow',
                                        title: Translation.get('feeds.addForm.columnsOptions.firstRow', 'feed-management')
                                    },
                                    {
                                        id: 'mapping',
                                        title: Translation.get('feeds.addForm.columnsOptions.columnRow', 'feed-management')
                                    }
                                ],
                                condition: "['csv','xlsx'].includes(data.type)"
                            },
                            {
                                type: 'chips',
                                model: 'columnNames',
                                condition: "['csv','xlsx'].includes(data.type) && data.firstRow === 'mapping'"
                            },
                            {
                                type: 'autocomplete',
                                model: 'permanentFields',
                                title: Translation.get('feeds.addForm.permanentFields', 'feed-management'),
                                options: structure,
                                inputType: 'multiple',
                                freeSolo: true,
                                acceptsNewValues: true
                            },
                            {
                                type: 'textarea',
                                model: 'middleware.postDownload',
                                title: Translation.get('feeds.addForm.middleware.postDownload', 'feed-management')
                            },
                            {
                                type: 'textarea',
                                model: 'middleware.preTransform',
                                title: Translation.get('feeds.addForm.middleware.preTransform', 'feed-management')
                            },
                            {
                                type: 'textarea',
                                model: 'middleware.postTransform',
                                title: Translation.get('feeds.addForm.middleware.postTransform', 'feed-management')
                            },
                            {
                                type: 'textarea',
                                model: 'middleware.preUpdate',
                                title: Translation.get('feeds.addForm.middleware.preUpdate', 'feed-management')
                            }
                        ]
                    }
                ]
            }
        ];

        // If dataset already exists.
        if (dataSetId) {
            // Add name fied
            formSetup[0].items.unshift({
                type: 'text',
                model: 'name',
                title: Translation.get('feeds.addForm.feedName', 'feed-management'),
                validators: 'required'
            });
        }

        return formSetup;
    }
};

/** empty feed */
const newData = {
    name: 'Input feed',
    type: '',
    schedule: 'DAILY',
    updateAction: 'append',
    removeNonExisting: 'false'
};

/** Some data tweaks to make form work
 * @param {object} feed - original feed;
 */
const transformData = (feed) => {
    const newData = { ...feed };

    // RadioGroup can't handle real booleans.
    if (newData.removeNonExisting) {
        newData.removeNonExisting = 'true';
    } else {
        newData.removeNonExisting = 'false';
    }
    // firstRow isn't stored in so we set it again based on columnNames
    if (Array.isArray(newData.columnNames) && newData.columnNames.length > 0) {
        newData.firstRow = 'mapping';
    }

    if (newData.mapping) {
        // The keys and values have to be reversed, because the backend expects the new column name first (but in the UI it's reversed)
        try {
            newData.mapping = Object.fromEntries(Object.entries(newData.mapping).map((v) => v.reverse()));
        } catch (err) {
            // Error when reversing object
        }
    }

    return newData;
};

/**
 * Get the title for the dialog.
 * @param {object} feed
 * @returns {string}
 */
const getTitle = (feed) => {
    return feed && feed.feedId ? Translation.get('feeds.addForm.editFeed', 'feed-management') : Translation.get('feeds.addForm.addFeed', 'feed-management');
};

/**
 * Get the copy for the button for the dialog.
 * @param {object} feed
 * @returns {string}
 */
const getBtnCopy = (feed) => {
    return feed && feed.feedId ? Translation.get('feeds.addForm.editFeed', 'feed-management') : Translation.get('feeds.addForm.addFeed', 'feed-management');
};

/**
 * Display the dialog with the form to add or edit feeds.
 * @param {*} param0
 * @returns
 */
const FeedFormDialog = ({
    dataSetId,
    feed,
    inCampaign = false,
    callback = () => {},
    onClose = () => {},
    setup,
    structure,
    view,
    showSaveNotification = true
}) => {
    const [formSetup, setFormSetup] = useState(getFormSetup(setup, dataSetId, structure));
    const [formData, setFormData] = useState(transformData(feed && feed.feedId ? feed : newData));
    const [title, setTitle] = useState(getTitle(feed));
    const [btnCopy, setBtnCopy] = useState(getBtnCopy(feed));

    const referenceFeedId = useRef(feed ? feed.feedId : undefined);

    useEffect(() => {
        setFormSetup(getFormSetup(setup, dataSetId, structure));
    }, [setup, dataSetId, structure]);

    useEffect(() => {
        if (feed && feed.feedId !== referenceFeedId.current) {
            referenceFeedId.current = feed.feedId;
            setFormData(transformData(feed.feedId ? feed : newData));
            setTitle(getTitle(feed));
            setBtnCopy(getBtnCopy(feed));
        }
    }, [feed]);

    /** Clean up form data before sending it to the API
     * @param {object} feed - form data to clean up.
     */
    const tidyData = (feed) => {
        if (feed.firstRow) {
            if (feed.firstRow === 'firstRow') {
                feed.columnNames = 'firstRow';
            }
            delete feed['firstRow'];
        }
        if (feed.removeNonExisting) {
            feed.removeNonExisting = feed.removeNonExisting === 'true';
        }

        return feed;
    };

    /**
     * This form serves different purposes.
     * Determines which method to execute after submitting.
     * @param {object} data
     */
    const handleSubmit = (feed) => {
        if (!dataSetId) {
            // No dataSet exists for this feed. First create one.
            if (inCampaign) {
                return Promise.resolve(addDataSet(feed));
            } else {
                throw new Error('Unknown scenario');
            }
        } else if (feed.feedId) {
            // We are updating an existing feed.
            return Promise.resolve(handleUpdate(tidyData(feed)));
        } else {
            // We are creating a new feed for an existing dataSet.
            return Promise.resolve(handleCreate(dataSetId, tidyData(feed)));
        }
    };

    /**
     * If the 'never' schedule is seleted for a feed. Patch the isEnabled key to always be false.
     * @param {object} feed
     * @returns
     */
    const patchFeedForNeverSchedule = (feed) => {
        const payload = { ...feed };
        if (payload.schedule === FeedHelpers.neverCron) {
            payload.isEnabled = false;
        }
        return payload;
    };

    /** Add a feed to a data set
     * @param {string} datasetId
     * @param {object} feed -
     */
    const handleCreate = (dataSetId, feed) => {
        const payload = patchFeedForNeverSchedule(feed);
        return Promise.resolve(
            FeedRequest.post(`dataset/${dataSetId}/feed`, payload)
                .then((data) => {
                    callback(data.data);
                    onClose();
                    return data.data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('feeds.addForm.addingFeedFailed', 'feed-management'));
                    throw new Error(error);
                })
        );
    };

    /** Add a feed to a data set
     * @param {object} feed -
     */
    const handleUpdate = (feed) => {
        if (!feed) return;
        const payload = patchFeedForNeverSchedule(feed);
        return Promise.resolve(
            FeedRequest.patch(`dataset/${dataSetId}/feed/${feed.feedId}`, payload)
                .then((data) => {
                    callback(data.data);
                    onClose();
                    return data.data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('updatingFeedFailed', 'feed-management'));
                    throw new Error(error);
                })
        );
    };

    /**
     * Add a new dataset and create a new schedule inside that dataset
     * @param {object} formData The data from the form
     * Example spreadsheets:    https://dump.lwdev.nl/feed-manager/Feed-manager-test-csv.csv
     *                          https://dump.lwdev.nl/feed-manager/Feed-manager-test-articles.csv
     */
    const addDataSet = (formData) => {
        const campaignId = EditorData.get('id');
        const campaignTitle = EditorData.get('title');

        const datasetPostData = {
            autoPublish: {
                campaignId: Number(campaignId),
                schedule: '',
                enabled: false,
                runAfterPoll: true
            },
            customData: {
                title: `${campaignTitle} - ${campaignId}`,
                campaignId
            },
            structure: {},
            feeds: []
        };

        return Promise.resolve(
            FeedRequest.post('dataset', datasetPostData)
                .then(async (data) => {
                    await createFeed(data.data._id, formData);
                    return data.data;
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('feeds.addForm.createDataSetFailed', 'feed-management'));
                    throw new Error(error);
                })
        );
    };

    /**
     * Create a new schedule in the given dataset
     * After it is created, force update the dataset
     * @param {string} datasetId The datasetId that is return
     * @param {object} formData The formData
     */
    const createFeed = (datasetId, formData) => {
        if (!datasetId) return;

        const newData = tidyData(formData);
        newData.name = 'Input feed';

        return Promise.resolve(handleCreate(datasetId, newData));
    };

    /**
     * Handles form data change
     * @param  key
     * @param  value
     */
    const handleOnFormDataChange = (key, value) => {
        const newFormData = { ...formData };

        if (key === 'url' && value) {
            const type = getFeedTypeByUrl(value);
            newFormData.type = type ? type : '';
            setFormData(newFormData);
        }
    };

    /**
     * Gets the feed type based on the url
     * @param {string} url
     * @returns Gets the feed type based on the url
     */
    const getFeedTypeByUrl = (url) => {
        if (url.match('docs.google.com/spreadsheets/d/([a-zA-Z0-9-_]+)')) return 'spreadsheet';
        else if (url.match('.+(.csv)$')) return 'csv';
        else if (url.match('.+(.rss)$')) return 'rss';
        else if (url.match('.+(.xml)$')) return 'xml';
        else if (url.match('.+(.xlsx)$')) return 'xlsx';
        else if (url.match('.+(.json)$')) return 'json';
    };

    return (
        <div className="feed-management-feed-form-dialog">
            <FormFlow
                onSubmit={handleSubmit}
                onChange={(key, value) => handleOnFormDataChange(key, value)}
                data={formData}
                setup={formSetup}
                onSubmitComplete={() => {}}
                onCloseDialog={onClose}
                submitButtonLabel={btnCopy}
                title={title}
                view={view}
                modalOpen
                saveNotification={
                    feed && feed.feedId
                        ? Translation.get('feeds.addForm.feedUpdated', 'feed-management')
                        : Translation.get('feeds.addForm.feedAdded', 'feed-management')
                }
                showSaveNotification={showSaveNotification}
            />
        </div>
    );
};

export default FeedFormDialog;
